(function() {
	var system = $('.qtde__controls');

	system.each(function() {
		var $this = $(this);
		var btn   = $this.find('.qtde__controls__btn');
		var input = $this.find('.qtde__controls__input');

		btn.click(function() {
			var fn = $(this).data('fn');
			var val = input.val();
			var direction;

			if(isNaN(val)) return false;

			val=parseInt(val);

			if(fn === 'minus') {
				direction = -1;
			}else {
				direction = 1;
			}

			if(direction===-1){
				if(val===1) {
					return false;
				}
			}

			input.val(val+direction);
		});
	});
})();
