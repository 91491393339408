(function() {
	var target   = $('#menu, body');
	var btn      = $('#toggle__menu');
	var expanded = false;

	btn.click(function(e) {
		e.preventDefault();

		btn.blur();

		toggle();
	});

	function toggle() {
		target.toggleClass('menu--shown');
		expanded = !expanded;

		btn.attr('aria-expanded', expanded)
	}

})();
