(function() {
	var body   = document.body;
	var target = document.querySelector('.topo .user');
	var btn    = document.querySelector('#toggle__user__mbl');
	var state  = false;

	body.addEventListener('click', function(event) {
		target.classList.remove('show');
		state = false;
		btn.setAttribute('aria-expanded', 'false')
	})

	btn.addEventListener('click', function(event) {
		event.stopPropagation();

		state = !state;
		target.classList.toggle('show')
		btn.setAttribute('aria-expanded', state)
	})
})();
