window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame || function(f){setTimeout(f, 1000/60)};
window.cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame || window.webkitCancelAnimationFrame ||  window.msCancelAnimationFrame || function(animation) {clearInterval(animation)}

var midiaSize = (function() {

	verifyMidia();

	function verifyMidia () {
		var w = window.innerWidth;
		var size = 'xs';

		if(w < 500) {
			size = 'xs';
		}

		if(w >= 500) {
			size = 'sm';
		}

		if(w >= 768) {
			size = 'md';
		}

		if(w >= 992) {
			size = 'lg';
		}

		if(w >= 1200) {
			size = 'xl';
		}

		requestAnimationFrame(verifyMidia);

		return size;
	}

})();

const BODY 	= document.body;
const APP 	= document.querySelector('#app');
const path 	= basePath();

function basePath () {
	let p = '';

	if($('body').data('path')) {
		p = $('body').data('path') + '/';
	}

	return p;
}

