window.addEventListener('load', function() {
	const items = $(".lazy-embed");
	var template, iframe, container;

	items.each(function() {
		template  = this.querySelector('template');
		container = this;

		if(template) {
			iframe = template.content.querySelector('iframe');

			if(iframe) {
				iframe.setAttribute('loading', 'lazy');

				this.appendChild(iframe);
				this.removeChild(template);

				iframe.addEventListener('load', function() {
					container.classList.add('loaded')
				})
			}
		}
	})

});