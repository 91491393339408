$('.tipos__pessoa a').each(function() {
	var $this  = $(this);
	var target = $($this.attr('href'));
	var forms  = $('.form__pessoa');
	var pai    = $this.parent('li');
	var tio    = pai.siblings('li');

	$this.click(function(e) {
		e.preventDefault();

		tio.removeClass('active');
		forms.removeClass('active');
		pai.addClass('active');
		target.addClass('active');
	});
});
