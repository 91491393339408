(function() {
	var target   = $('.mobile__search, body');
	var btn      = $('#toggle__search');
	var topo     = document.querySelector('.topo');
	var input    = $('.mobile__search input');
	var expanded = false;

	btn.click(function(e) {
		e.preventDefault();

		toggle();

		var bck = document.createElement('div');

		bck.className = 'backdrop';

		$(bck).click(function() {
			toggle();

			$(bck).fadeOut(600, function() {
				$(this).remove();
			});
		});

		topo.appendChild(bck);

		btn.blur();
		input.focus();
	});

	function toggle() {
		expanded = !expanded;

		target.toggleClass('mobile__search--shown');

		btn.attr('aria-expanded', expanded);
	}

})();
