(function() {
	var observer;

	$('[data-lazy-bg]').each(function() {
		Observer(this);
	});

	function Observer($el) {
		var animation, rect, top, bottom, vh;
		var bg = $el.dataset.lazyBg;

		function check() {
			rect    = $el.getBoundingClientRect();
			top     = rect.top;
			bottom  = rect.bottom;
			vh      = window.innerHeight;

			if(top <= (vh+200) && bottom >= -200) {
				$el.style.setProperty('--lazy-bg', 'url('+JSON.stringify(bg)+')');
				$el.removeAttribute('data-lazy-bg');

				clearInterval(animation);
			}
		}

		animation = setInterval(check, (1000/60));
	}

})();
